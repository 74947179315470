import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';
import { isOwner } from './index';

const canDgdBeVerifiedFn = (state: RootState) => state.dgdData.dgdCanBeVerified;
const canUserValidateFn = (state: RootState) => state.applicationState.canValidateDgdIfOwner;
const canAcceptanceCheckFn = (state: RootState) => state.applicationState.canAcceptanceCheckForSubscription;
const acceptanceCheckStateFn = (state: RootState) => state.acceptanceCheckState;

export const checkRequiresValidation = createSelector(
  [ acceptanceCheckStateFn ],
  ( acceptanceCheckState: AcceptanceCheckState) : boolean => {

    const { status } = acceptanceCheckState;

      let acceptableStatuses : AcceptanceCheckStatus[] = [
        'VerificationRequired',
        'VerificationInProgress'
      ];

    return acceptableStatuses.indexOf(status) > -1;
  }
)

export const canCheckBeValidatedInCurrentState = createSelector(
  [ canAcceptanceCheckFn,
    acceptanceCheckStateFn ],
  ( canAcceptanceCheck: boolean, acceptanceCheckState: AcceptanceCheckState) : boolean => {

    const { status, signedOffBy } = acceptanceCheckState;

    if (signedOffBy)
      return false;

      let acceptableStatuses : AcceptanceCheckStatus[] = [
        'VerificationRequired',
        'VerificationInProgress',
        'AwaitingDocumentCheck'
      ];
      if (canAcceptanceCheck) {
        acceptableStatuses = acceptableStatuses.concat([
          'DocumentationCheckInProgress'
        ])
      }

    return acceptableStatuses.indexOf(status) > -1;
  }
)

export const canCheckBeValidatedInCurrentStateByUser = createSelector(
  [ canCheckBeValidatedInCurrentState,
    canDgdBeVerifiedFn,
    canUserValidateFn,
    canAcceptanceCheckFn,
    isOwner,
    acceptanceCheckStateFn ],
  ( validationIsPossible: boolean, canDgdBeVerified: boolean, canUserValidate: boolean, canAcceptanceCheck: boolean, isOwner: boolean, acceptanceCheckState: AcceptanceCheckState) : boolean => {

    const { status, signedOffBy } = acceptanceCheckState;

    if (!validationIsPossible || !canDgdBeVerified)
      return false;

    if (!canUserValidate)
      return false;

    return isOwner;
  }
)

export const canCheckBeValidatedInCurrentStateByUserIfOwner = createSelector(
  [ canCheckBeValidatedInCurrentState,
    canDgdBeVerifiedFn,
    canUserValidateFn,
    acceptanceCheckStateFn ],
  ( validationIsPossible: boolean, canDgdBeVerified: boolean, canUserValidate: boolean, acceptanceCheckState: AcceptanceCheckState) : boolean => {

    if (!validationIsPossible || !canDgdBeVerified)
      return false;

    return canUserValidate;
  }
)
