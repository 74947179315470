import { createAction } from 'redux-actions';
import { Actions } from '../../constants';
import { Dispatch } from 'redux';
import { asyncRequest } from 'communication/async-request';

const dgdResourceIsSaving = createAction<boolean>(Actions.SUBMIT_RESOURCE);
const dgdResourceLoad = createAction<DgdResources>(Actions.LOAD_RESOURCE_SECTIONS);
const dgdResourceDelete = createAction<number>(Actions.DELETE_RESOURCE_SECTION);
const dgdResourcesEditStatus = createAction<RuleTypeVisibility>(Actions.DGD_RESOURCES_EDIT);

export function createDgdResourceSection( createUrl: string, listUrl: string, comment: string, displayName: string, blobName: string, sectionId: any): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {
    dispatch( dgdResourceIsSaving( true ) )

    var data = {
      blobName: blobName,
      sectionId: sectionId,
      displayName: displayName,
      comment: comment
    };

    asyncRequest({
      url: createUrl,
      method: 'POST',
      data: data,
      callback: function(response: jsendResponse) {
        switch (response.status) {
          case 'success':
            dispatch( dgdResourceIsSaving( false ) )
            fetchDgdResourceSections( listUrl )(dispatch)
            editDgdResourceSection( false )(dispatch)
            break;
          case 'error':
          case 'fail':
            dispatch( dgdResourceIsSaving( false ) )
            alert('Creating the new resource failed. Please try again.');
            break;
        }
      }
    });
  }
}

export function editDgdResourceSection( payload: string | boolean):(dispatch: Dispatch<ReduxActions.Action<any>>) => void {
  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {
    var userSettingsData = {
      ...userSettingsData,
      dgdResourceEditing: payload
    } as RuleTypeVisibility

    dispatch(dgdResourcesEditStatus(userSettingsData))
  }
}

export function editingDgdResourceSection( saveUrl: string, listUrl: string, comment: string, displayName: string, blobName: string, sectionId: number): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {

  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {
    dispatch( dgdResourceIsSaving( true ) )

    var data = {
      blobName: blobName,
      sectionId: sectionId,
      displayName: displayName,
      comment: comment
    };

    asyncRequest({
      url: saveUrl,
      method: 'PUT',
      data: data,
      callback:function(response: jsendResponse) {
        switch (response.status) {
          case 'success':
            dispatch( dgdResourceIsSaving( false ) )
            fetchDgdResourceSections( listUrl )(dispatch)
            editDgdResourceSection( false )(dispatch)
            break;
          case 'error':
          case 'fail':
            dispatch( dgdResourceIsSaving( false ) )
            alert('Updating the resource failed. Please try again.');
            break;
        }
      }
    });
  }
}

// Fetch all resources
export function fetchDgdResourceSections(listUrl: string):(dispatch: Dispatch<ReduxActions.Action<any>>) => void {
  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    asyncRequest({
      url: listUrl,
      callback: function(response: jsendResponse) {
        switch (response.status) {
          case 'success':
              dispatch(dgdResourceLoad( response.data as DgdResources ));
            break;
          case 'error':
          case 'fail':
            alert('Updating the resource failed. Please try again.');
            break;
        }
      }
    });
  }
}

export function deleteDgdResourceSection( deleteResourceUrl: string, listUrl: string ): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {
  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {

    asyncRequest({
      url: deleteResourceUrl,
      method: 'DELETE',
      callback: function(response: jsendResponse) {
        switch (response.status) {
          case 'success':
            fetchDgdResourceSections( listUrl )(dispatch)
            editDgdResourceSection( false )(dispatch)
            break;
          case 'error':
          case 'fail':
            alert('Deleting the resource failed. Please try again.');
            break;
        }
      }
    });
  }
}
