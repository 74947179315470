import { RootState } from 'checkspa/reducers';
import { createSelector } from 'reselect';
import { UrlTemplates } from 'checkspa/constants';

const dgdFn = (dgd: DgdData) => dgd;

export const dgdVerifiedBy = createSelector(
  [ dgdFn ],
  (dgd: DgdData) : [UserDetails[], boolean] => {

    var dgVerifiers = dgd.dangerousGoods.map(w => w.validatedBy);
    var dgdVerifiers = [
      safeValidatedBy(dgd.awbMeta),
      safeValidatedBy(dgd.shippersReferenceMeta),
      safeValidatedBy(dgd.aircraftTypeMeta),
      safeValidatedBy(dgd.originMeta),
      safeValidatedBy(dgd.destinationMeta),
      safeValidatedBy(dgd.additionalHandlingInformationMeta),
      safeValidatedBy(dgd.consigneeMeta),
      safeValidatedBy(dgd.shipperMeta),
    ];

    var verifiers = dgVerifiers
      .concat(dgdVerifiers)
      .filter(w => w)
      .filter((value, index, arr) => arr.findIndex(w => w.userId == value.userId) === index);


    var onlyVerifiedByPreviousParties = verifiers.length > 0 && verifiers.every(user => user.fullName === 'previous parties');

    return [verifiers, onlyVerifiedByPreviousParties];
  }
)

const safeValidatedBy = (meta: DgdValueMeta) => {
  return meta ? meta.validatedBy : null
}